import {
  AuctionBiddingStatus,
  Brand,
  EBrand,
  LotStatus,
  SaleStatus,
} from '@bonhams1793/bonhams-typescript'
import type { GetServerSidePropsContext } from 'next'

import { AlgoliaAuction, AlgoliaAuctionResponse } from '@nx/algolia'
import { getCookiesFromApp } from '@nx/cookies'
import { getDateNowUtc } from '@nx/dates'
import { LD_ID_COOKIE_NAME } from '@nx/external/launch-darkly-common'
import { AuctionLot, BonhamsCookies } from '@nx/global-types'
import { TypesenseAuction, TypesenseAuctionResponse } from '@nx/typesense'
import { logger } from '@nx/utils/logger'

import { CornetteHomePage } from '@web/components/brand/cornette/CornetteHomePage'
import { SkinnerHomePage } from '@web/components/brand/skinner/SkinnerHomePage'
import { HomePage } from '@web/components/home'
import {
  getTypesenseAuctions,
  getTypesenseAuctionsByIDs,
} from '@web/helpers/api'
import {
  getAlgoliaAuctions,
  getAlgoliaAuctionsByObjectIDs,
  getAlgoliaLotsByObjectIDs,
} from '@web/helpers/api/algolia'
import { getUpcomingAuctions } from '@web/helpers/api/auction'
import { getCarouselItems } from '@web/helpers/api/home/getCarouselItems'
import { getNewsAndVideos } from '@web/helpers/api/home/getNewsAndVideos'
import { getLaunchDarklyFlagServer } from '@web/helpers/api/launchDarkly/server'
import { getPersonalisedRecommendations } from '@web/helpers/api/recommendation/crossingMinds/getPersonalisedRecommendations'
import { FeatureFlags } from '@web/helpers/enums'
import { getSiteBrand, withRedirects } from '@web/helpers/utils'

import {
  IndexProps,
  bonhamsAuctions,
  cornetteAuctions,
  skinnerAuctions,
} from './index.types'

export function Index(props: IndexProps) {
  switch (props.globalProps.brand) {
    case Brand.skinner:
      return (
        <SkinnerHomePage
          {...props}
          auctions={props.auctions as skinnerAuctions}
        />
      )
    case Brand.cornette:
      return (
        <CornetteHomePage
          {...props}
          auctions={props.auctions as cornetteAuctions}
        />
      )
    default:
      return (
        <HomePage
          {...props}
          userIp={props.globalProps.user?.ip}
          auctions={props.auctions as bonhamsAuctions}
        />
      )
  }
}

async function indexServerSideProps(context: GetServerSidePropsContext) {
  context.req.cookies = {
    ...getCookiesFromApp(context.res),
    ...context.req.cookies,
  }

  const isTypesenseGlobalEnabled = !!(await getLaunchDarklyFlagServer({
    ldId: context.req.cookies[LD_ID_COOKIE_NAME],
    key: 'typesense-auctions-global',
  }))

  const isTypesenseHomepageEnabled = !!(await getLaunchDarklyFlagServer({
    ldId: context.req.cookies[LD_ID_COOKIE_NAME],
    key: 'typesense-homepage',
  }))

  const isTypesenseEnabled =
    isTypesenseGlobalEnabled && isTypesenseHomepageEnabled

  const brand = getSiteBrand(
    context.req.headers.host,
    context.req.cookies[FeatureFlags.SITE_BRAND] as EBrand
  )

  const fetchedProps =
    brand === Brand.skinner
      ? await fetchSkinnerData(isTypesenseEnabled)
      : brand === Brand.cornette
        ? await fetchCornetteData(isTypesenseEnabled)
        : await fetchBonhamsData(context, isTypesenseEnabled)

  return {
    props: { ...fetchedProps, host: context.req.headers.host },
  }
}

const fetchBonhamsData = async (
  pageContext: GetServerSidePropsContext,
  isTypesenseEnabled: boolean
): Promise<Omit<IndexProps, 'globalProps'>> => {
  const cookies = pageContext.req.cookies

  const { default: Cookies } = await import('cookies')
  const cookieJar = new Cookies(pageContext.req, pageContext.res)
  const isCrossingMindsEnabled = await getLaunchDarklyFlagServer({
    ldId: cookieJar.get(LD_ID_COOKIE_NAME),
    key: 'crossing-minds',
  })

  let auctions: TypesenseAuctionResponse | AlgoliaAuctionResponse | null = null

  try {
    auctions = await getUpcomingAuctions(isTypesenseEnabled)
  } catch (error) {
    logger.error(error, 'auctions collection - getUpcomingAuctions')
  }

  const [
    [recommendedAuctions, recommendedAuctionIds],
    [recommendedLots, recommendedLotIds],
    carouselItems,
    newsAndVideos,
  ] = await Promise.all([
    isCrossingMindsEnabled
      ? fetchBonhamsRecommendedAuctions(cookies, isTypesenseEnabled)
      : Promise.resolve([[], []]),
    isCrossingMindsEnabled
      ? fetchBonhamsRecommendedLots(cookies)
      : Promise.resolve([[], []]),
    getCarouselItems(Brand.bonhams),
    getNewsAndVideos(Brand.bonhams),
  ])

  const oneHourInMilliseconds = 60 * 60 * 1000

  cookieJar.set('rec_auctions', recommendedAuctionIds.join(','), {
    path: '/',
    maxAge: oneHourInMilliseconds,
    httpOnly: false,
  })
  cookieJar.set('rec_lots', recommendedLotIds.join(','), {
    path: '/',
    maxAge: oneHourInMilliseconds,
    httpOnly: false,
  })

  return {
    carouselItems,
    auctions: {
      active: auctions?.hits || [],
      recommended: recommendedAuctions,
    },
    lots: [recommendedLots],
    newsAndVideos: newsAndVideos.results,
    regionCounts:
      auctions?.facets && auctions.facets['region.code']
        ? auctions.facets['region.code']
        : null,
    isTypesenseEnabled,
  }
}

const fetchSkinnerData = async (
  isTypesenseEnabled: boolean
): Promise<Omit<IndexProps, 'globalProps'>> => {
  let skinner: TypesenseAuctionResponse | AlgoliaAuctionResponse | null = null
  let moreAuctions: TypesenseAuctionResponse | AlgoliaAuctionResponse | null =
    null

  const skinnerFilters = {
    biddingStatus: { value: AuctionBiddingStatus.ended, exclude: true },
    brand: Brand.skinner,
    futureOnly: true,
    ...(isTypesenseEnabled
      ? {
          filter_by: 'lots.total:>0',
          per_page: 12,
          sort_by: 'hammerTime.timestamp:asc,auctionTitle:desc',
          facet_by: 'region.code',
        }
      : {
          numericFilters: ['lots.total > 0'],
          hitsPerPage: 12,
          facets: ['region.code'],
        }),
  }

  try {
    skinner = await (
      isTypesenseEnabled ? getTypesenseAuctions : getAlgoliaAuctions
    )(skinnerFilters)
  } catch (error) {
    logger.error(
      error,
      `auctions collection - ${JSON.stringify(skinnerFilters)}`
    )
  }

  const moreAuctionsFilters = {
    biddingStatus: { value: AuctionBiddingStatus.ended, exclude: true },
    auctionStatus: SaleStatus.ready,
    futureOnly: true,
    brand: [Brand.bonhams, Brand.cornette, Brand.cars],
    ...(isTypesenseEnabled
      ? {
          filter_by: 'lots.total:>0',
          per_page: 12,
          sort_by: 'hammerTime.timestamp:asc,auctionTitle:desc',
        }
      : {
          numericFilters: ['lots.total > 0'],
          hitsPerPage: 12,
        }),
  }

  try {
    moreAuctions = await (
      isTypesenseEnabled ? getTypesenseAuctions : getAlgoliaAuctions
    )(moreAuctionsFilters)
  } catch (error) {
    logger.error(
      error,
      `auctions collection - ${JSON.stringify(moreAuctionsFilters)}`
    )
  }

  const [carouselItems, newsAndVideos] = await Promise.all([
    getCarouselItems(Brand.skinner),
    getNewsAndVideos(Brand.skinner),
  ])

  return {
    carouselItems: carouselItems,
    auctions: {
      skinner: skinner?.hits || [],
      bonhams: moreAuctions?.hits || [],
    },
    newsAndVideos: newsAndVideos.results,
    isTypesenseEnabled,
  }
}

const fetchCornetteData = async (
  isTypesenseEnabled: boolean
): Promise<Omit<IndexProps, 'globalProps'>> => {
  let cornetteAuctions:
    | TypesenseAuctionResponse
    | AlgoliaAuctionResponse
    | null = null
  const cornetteFilters = {
    biddingStatus: { value: AuctionBiddingStatus.ended, exclude: true },
    brand: Brand.cornette,
    futureOnly: true,
    ...(isTypesenseEnabled
      ? {
          filter_by: 'lots.total:>0',
          per_page: 12,
          sort_by: 'hammerTime.timestamp:asc,auctionTitle:desc',
        }
      : {
          numericFilters: ['lots.total > 0'],
          hitsPerPage: 12,
        }),
  }

  try {
    cornetteAuctions = await (
      isTypesenseEnabled ? getTypesenseAuctions : getAlgoliaAuctions
    )(cornetteFilters)
  } catch (error) {
    logger.error(
      error,
      `auctions collection - ${JSON.stringify(cornetteFilters)}`
    )
  }

  const [carouselItems, newsAndVideos] = await Promise.all([
    getCarouselItems(Brand.cornette),
    getNewsAndVideos(Brand.cornette),
  ])

  return {
    carouselItems: carouselItems,
    auctions: { cornette: cornetteAuctions?.hits || [] },
    newsAndVideos: newsAndVideos.results,
    isTypesenseEnabled,
  }
}

const fetchBonhamsRecommendedAuctions = async (
  cookies: BonhamsCookies & Partial<Record<string, string>>,
  isTypesenseEnabled: boolean
) => {
  let recommendedAuctions: (AlgoliaAuction | TypesenseAuction)[] = []
  let recommendedAuctionsIDs: string[] | number[]

  if (cookies['rec_auctions']) {
    recommendedAuctionsIDs = cookies['rec_auctions'].split(',')
  } else {
    recommendedAuctionsIDs = await getPersonalisedRecommendations(
      'auctions',
      cookies.bonhams_sid ?? cookies.xm_id
    )
  }

  if (recommendedAuctionsIDs.length) {
    let recommendedAuctionsResponse:
      | (AlgoliaAuction | null)[]
      | (TypesenseAuction | null)[] = []

    try {
      recommendedAuctionsResponse = isTypesenseEnabled
        ? await getTypesenseAuctionsByIDs(recommendedAuctionsIDs)
        : await getAlgoliaAuctionsByObjectIDs({
            objectIDs: recommendedAuctionsIDs,
          })

      recommendedAuctions = recommendedAuctionsResponse
        ?.filter(Boolean)
        ?.filter(
          (auction) =>
            auction.auctionStatus === SaleStatus.ready &&
            auction.dates.end.timestamp > getDateNowUtc().seconds
        )
        ?.slice(0, 4)
    } catch (error) {
      logger.error(
        error,
        `auctions collection - auctionIds: ${recommendedAuctionsIDs}`
      )
    }
  }

  return [recommendedAuctions, recommendedAuctionsIDs] as const
}

const fetchBonhamsRecommendedLots = async (
  cookies: BonhamsCookies & Partial<Record<string, string>>
) => {
  let recommendedLots: AuctionLot[] = []
  let recommendedLotIDs: string[] | number[]

  if (cookies['rec_lots']) {
    recommendedLotIDs = cookies['rec_lots'].split(',')
  } else {
    recommendedLotIDs = await getPersonalisedRecommendations(
      'lots',
      cookies.bonhams_sid ?? cookies.xm_id
    )
  }

  if (recommendedLotIDs.length) {
    const recommendedLotsResponse = await getAlgoliaLotsByObjectIDs({
      objectIDs: recommendedLotIDs,
    })

    recommendedLots = recommendedLotsResponse.results
      ?.filter(Boolean)
      ?.filter((lot) => lot.status === LotStatus.new)
  }

  return [recommendedLots, recommendedLotIDs] as const
}

export const getServerSideProps = withRedirects(indexServerSideProps)

export default Index
