import getConfig from 'next/config'
import dynamic from 'next/dynamic'

import { AlgoliaAuction } from '@nx/algolia'
import { FireHeroImageCarousel } from '@nx/fire/hero-image-carousel'
import { Section } from '@nx/fire/section'
import VisuallyHidden from '@nx/fire/visually-hidden'
import { HeroCarouselItemsResponse } from '@nx/get-carousel-items'
import { AuctionLot, NewsAndVideosItem } from '@nx/global-types'
import { getTranslate } from '@nx/translations'
import { TypesenseAuction } from '@nx/typesense'
import { useUserInteraction } from '@nx/utils/google-tag-manager'

import { HeroImageCarouselInfoBox } from '@web/components/common/HeroImageCarouselInfoBox'
import { MetaData } from '@web/components/common/MetaData'
import { NewsAndVideos } from '@web/components/common/NewsAndVideos'
import { AuctionCalendar } from '@web/components/home/AuctionCalendar'
import { HomePageAuctions } from '@web/components/home/HomePageAuctions'
import { HomePageLots } from '@web/components/home/HomePageLots'
import { LiveAuctions } from '@web/components/home/LiveAuctions'
import { getLiveAuctions } from '@web/helpers/auctions'
import { populateUrl } from '@web/helpers/utils/urlBuilders/populateUrl'
import { IndexProps } from '@web/pages/index.types'

import { StyledButtonContainer, StyledFireButton } from './HomePage.styles'
import translate from './HomePage.translate.json'

const DynamicHomePageComponents = dynamic(
  () =>
    import('./DynamicHomePageComponents').then(
      ({ DynamicHomePageComponents }) => DynamicHomePageComponents
    ),
  {
    ssr: false,
  }
)

const { publicRuntimeConfig } = getConfig()

const { text } = getTranslate(translate)

export function HomePage({
  carouselItems,
  auctions,
  lots = [],
  regionCounts,
  newsAndVideos,
  userIp,
  isTypesenseEnabled,
}: HomePageProps) {
  const { recordInteraction } = useUserInteraction()

  const recommendedLots = lots[0] || []

  const liveAuctions = getLiveAuctions(auctions.active)

  return (
    <>
      <MetaData
        name={text('homePage.meta.title')}
        description={text('homePage.meta.description')}
        imageUrl={populateUrl('/web-assets/bonhams-logo.png')}
        baseUrl={publicRuntimeConfig.WEB_BASE_URL}
      />
      <VisuallyHidden as="h1">Bonhams</VisuallyHidden>
      {!!carouselItems.length && (
        <Section verticalSpacing="none" bleed="partial" id="homepage-carousel">
          <FireHeroImageCarousel
            slides={carouselItems.map((item) => ({
              id: item.id,
              title: item.title,
              largeImage: item.image,
              smallImage: item.mobile_image,
              infoBox: (
                <HeroImageCarouselInfoBox
                  title={item.title}
                  description={item.desc}
                  dateAndLocation={item.date_and_location}
                  link={item.link}
                  ctaTitle={item.ctaTitle}
                />
              ),
            }))}
          />
        </Section>
      )}

      {!!liveAuctions.length && (
        <Section>
          <LiveAuctions
            auctions={liveAuctions}
            auctionClickCallback={({ auctionId, category, action }) => {
              recordInteraction({
                category: `Home Page - ${category}`,
                action,
                label: `${auctionId}`,
              })
            }}
          />
        </Section>
      )}

      {!!auctions.recommended.length && (
        <Section>
          <HomePageAuctions
            pageIdPrefix="recommended"
            title={text('homePage.auctions.recommended')}
            auctions={auctions.recommended}
            auctionClickCallback={({ auctionId, category, action }) => {
              recordInteraction({
                category: `Home Page - ${category}`,
                action,
                label: `${auctionId}`,
              })
            }}
          />
        </Section>
      )}

      {!!recommendedLots.length && (
        <Section background="white">
          <HomePageLots
            title={text('homePage.lots.recommended')}
            lots={recommendedLots}
          />
        </Section>
      )}

      {!!auctions.active.length && (
        <Section>
          <AuctionCalendar
            title={text('homePage.auctions.nearYou')}
            csrAuctions={auctions.active}
            regionCounts={regionCounts}
            ip={userIp}
            auctionClickCallback={({ auctionId, category, action }) => {
              recordInteraction({
                category: `Home Page - ${category}`,
                action,
                label: `${auctionId}`,
              })
            }}
            isTypesenseEnabled={isTypesenseEnabled}
          />
        </Section>
      )}

      <DynamicHomePageComponents />

      {!!newsAndVideos.length && (
        <Section>
          <NewsAndVideos
            title={text('homePage.press.title')}
            newsItemHeadingTag="h3"
            items={newsAndVideos.map(({ id, title, type, image }) => ({
              id,
              title,
              type,
              headingTag: 'h3',
              image: image
                ? populateUrl(image.url, image.URLParams, {
                    width: '640',
                  })
                : '',
            }))}
            trackingCategory="Home Page - News and Stories"
          />
          <StyledButtonContainer>
            <StyledFireButton
              variant="label"
              size="L"
              href="/press_release"
              forceAnchor
            >
              {text('homePage.press.view-more')}
            </StyledFireButton>
          </StyledButtonContainer>
        </Section>
      )}
    </>
  )
}

export interface HomePageProps {
  carouselItems: HeroCarouselItemsResponse
  auctions: {
    active: (AlgoliaAuction | TypesenseAuction)[]
    recommended: (AlgoliaAuction | TypesenseAuction)[]
  }
  lots?: AuctionLot[][]
  regionCounts?: IndexProps['regionCounts']
  newsAndVideos: NewsAndVideosItem[]
  userIp?: string
  isTypesenseEnabled: boolean
}
