import getConfig from 'next/config'

import { AlgoliaAuction } from '@nx/algolia'
import { FireAuctionGrid } from '@nx/fire/auction-grid'
import { Heading } from '@nx/fire/typography'
import { TypesenseAuction } from '@nx/typesense'

import { getAuctionDomain } from '@web/helpers/auctions'
import { populateUrl } from '@web/helpers/utils/urlBuilders/populateUrl'
import { AuctionClickCallback } from '@web/types/app'

import {
  StyledButtonContainer,
  StyledFireButton,
} from '../HomePage/HomePage.styles'

const { publicRuntimeConfig } = getConfig()
const auctionsPerPage = publicRuntimeConfig.AUCTIONS_PER_PAGE

export function HomePageAuctions({
  title,
  auctions,
  ctaText,
  pageIdPrefix,
  auctionClickCallback,
  host,
}: HomePageAuctionsProps) {
  return (
    <>
      <Heading $level={3} as="h2">
        {title}
      </Heading>
      <FireAuctionGrid
        auctionTitleTag="h3"
        pageIdPrefix={pageIdPrefix}
        auctions={auctions}
        auctionClickCallback={(props) =>
          auctionClickCallback?.({
            ...props,
            category: 'Recommended Auctions',
          })
        }
        auctionsPerPage={auctionsPerPage}
        urlBuilder={populateUrl}
        getDomain={getAuctionDomain}
        host={host}
      />
      {ctaText && (
        <StyledButtonContainer>
          <StyledFireButton variant="label" size="L" href="/auctions/upcoming/">
            {ctaText}
          </StyledFireButton>
        </StyledButtonContainer>
      )}
    </>
  )
}

interface HomePageAuctionsProps {
  title: string
  auctions: (AlgoliaAuction | TypesenseAuction)[]
  ctaText?: string
  pageIdPrefix: string
  auctionClickCallback?: AuctionClickCallback
  host?: string
}
