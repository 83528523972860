import {
  AuctionProgressStatus,
  getAuctionProgressStatus,
} from '@bonhams1793/bonhams-typescript'

import { AlgoliaAuction } from '@nx/algolia'
import { TypesenseAuction } from '@nx/typesense'

export const getLiveAuctions = (
  auctions: (AlgoliaAuction | TypesenseAuction)[]
) =>
  auctions.filter((auction) =>
    [AuctionProgressStatus.live, AuctionProgressStatus.ending].includes(
      getAuctionProgressStatus({
        status: auction.auctionStatus,
        type: auction.auctionType,
        biddingStatus: auction.biddingStatus,
        endDate: auction.dates.end.datetime,
        startDate: auction.dates.start.datetime,
        localTimezone: auction.dates.start.timezone.iana,
        consignmentEndDate:
          (auction.dates.consignment?.timestamp || 0) * 1000 || null,
      })
    )
  )
